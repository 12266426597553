.containerOverrides {
  overflow: visible;
}

.modal {
  max-width: 20rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.25rem;
  text-align: center;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.18);
}

.body {
  margin-bottom: 1.5rem;
}

.heading {
  composes: ui20SemiBold from 'styles/typeography.css';
}

.para {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray10);
}
