.formContainer {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background: white;
}
.heading {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin: 0;
}
.fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.divider {
  border-top: 1px solid var(--colorTan3);
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

/* Submit Button Section */
.buttonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.backButton {
  padding: 8px 12px;
}
