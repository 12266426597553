.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: white;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  padding: 28px 24px 24px 24px;
  color: var(--colorBlack2);
}

.header {
  composes: ui20SemiBold from 'styles/typeography.css';
  margin-bottom: 16px;
}

.text {
  composes: ui14Regular from 'styles/typeography.css';
  margin-bottom: 24px;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
