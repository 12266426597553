.editBusinessDetailsButtion {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  align-self: flex-end;
}

.fieldset {
  display: flex;
  flex-direction: column;
}
