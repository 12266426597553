.formContainer {
  padding: 1.5rem;
  background: white;
}

.heading {
  composes: ui14SemiBold from 'styles/typeography.css';
}

/* Submit Button Section */
.buttonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-top: 1.5rem;
}
.backButton {
  padding: 8px 12px;
}
