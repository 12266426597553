.wrapper {
  container-type: inline-size;
}

.alert {
  /* for some reason this alert uses a different shade of blue to the default 'info' alert */
  background-color: var(--colorBlue2) !important;
  max-width: 640px;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  border-left: 4px solid var(--alertBlueBarColor);
  padding: 24px 24px 24px 20px;
}

.isPreviewMode {
  pointer-events: none;
  cursor: default;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  gap: 1.5rem;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.right {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@container (max-width: 768px) {
  .alert {
    border-left: none;
    border-top: 6px solid var(--alertBlueBarColor);
  }

  .container {
    flex-direction: column;
  }

  .left {
    align-items: center;
    width: 100%;
  }

  .button {
    width: 100%;
  }
}

.image {
  height: 192px;
  min-width: 192px;
}

@media (--viewportTabletLandscapeMin) and (--viewportTabletLandscapeMax) {
  .image {
    height: 182px;
    min-width: 182px;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.title {
  composes: ui16SemiBold from '../../../../../styles/typeography.css';
  color: black !important;
  margin-bottom: 8px;
}

.description {
  composes: ui14Regular from '../../../../../styles/typeography.css';
  margin: 0;
  margin-bottom: 1.5rem;
  color: var(--colorGray8);
  margin-bottom: 0.75rem;
}

.button {
  margin-top: 16px;
  margin-bottom: 8px;
}

.footerNote {
  composes: ui11Regular from '../../../../../styles/typeography.css';
  color: var(--colorGray8);
  margin: 0 !important;
}
