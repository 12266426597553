.modalContent {
  background-color: white;
  width: 416px;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modalContent::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.modalContent {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-bottom: 1px solid var(--colorGray2);
}
.modalTitle {
  composes: ui16SemiBold from '../../../../../styles/typeography.css';
  color: black;
  margin: 0;
  margin-bottom: 0.5rem;
}
.modalSubTitle {
  composes: ui14Regular from '../../../../../styles/typeography.css';
  color: var(--colorGray8);
  margin: 0;
  margin-bottom: 1.5rem;
}
.closeIcon {
  position: absolute;
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--colorGray7);
}
