.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
}

/* Image Square - Outermost image wrapper */

.imageSquare {
  position: relative;
  z-index: 0;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 0.75rem;
}

/* Image Wrapper - Innermost image wrapper */

.imageWrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: white;
  border: 1px solid var(--colorGray2);
  border-radius: 4px;
  transition: border 200ms ease, box-shadow 200ms ease;
}

.imageWrapper.isOrderItem {
  border-color: var(--colorBlue8);
  box-shadow: 0 0 0 4px var(--colorBlue2);
}

.imageWrapper img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.productBadge {
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.5rem;
}

.outOfStockBadge {
  position: absolute;
  z-index: 2;
  bottom: 6px;
  right: 6px;
}

.addToCartWrapper {
  padding-bottom: 0.5rem;
}

.productDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.productName {
  composes: ui14SemiBold from 'styles/typeography.css';
  color: var(--colorGray11);
  display: flex;
  align-content: flex-start;
  width: 100%;
  padding-bottom: 0;
  font-weight: 500;
}

.productName a {
  color: inherit;
}

.productCatalogName {
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorGray11);
}

.productSellerDisplayName {
  composes: ui12Regular from 'styles/typeography.css';
  color: var(--colorGray6);
}

.productCatalogName a,
.productSellerDisplayName a {
  color: inherit;
}

.productCatalogName a:hover,
.productSellerDisplayName a:hover {
  color: var(--colorBlue8);
}

.priceContainer {
  display: flex;
  composes: ui12Medium from 'styles/typeography.css';
  font-feature-settings: var(--priceLabelFontFeatures);
  margin-bottom: 0.5rem;
  color: var(--colorGray11);
}

.from {
  margin-right: 0.25rem;
}

.price {
  color: var(--colorGray11);
}

.price.hasSalePrice {
  color: var(--colorRed8);
}

.currencyCode {
  margin-left: 0.25rem;
  color: var(--colorGray6);
}

.unit {
  margin-left: 0.5rem;
}

.catalogMeta {
  padding-top: ;
}
