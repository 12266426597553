.quantityControls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border-radius: 2px; */
}

.quantityControls.expand {
  width: 100%;
  justify-content: space-between;
}

.quantityInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
  flex-grow: 1;
}

.quantityInput {
  position: relative;
  z-index: 0;
  width: 100%;
  /* max-width: 90px; */
  height: 2rem;
  padding: 6px 12px;
  border: 1px solid var(--colorGray3);
  border-radius: 2px;
  text-align: right;
  color: var(--colorTextDefault);
  composes: ui14Regular from 'styles/typeography.css';

  transition: border 200ms ease, box-shadow 200ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.quantityInput::placeholder {
  color: lightgrey;
}

.quantityInput:focus {
  outline: 0;
  border-color: var(--colorBlue);
  box-shadow: 0 0 0 4px var(--colorBlueFade);
}

.quantityInput:disabled {
  color: var(--colorGray9);
  background-color: var(--colorGray1);
  border-color: var(--colorGray4);
}

.inputIcon {
  position: absolute;
  z-index: 1;
  left: 0.375rem;
  cursor: default;
}

.quantityButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 2rem;
  padding: 0;
  background-color: var(--colorGray2);
  color: var(--colorTextDefault);
  border: none;
  border-radius: 2px;
  transition: color 200ms ease, border-color 200ms ease;
  cursor: pointer;
}

.quantityButton + .quantityButton {
  margin-left: 8px;
}

.quantityButton:disabled,
.quantityButton[disabled] {
  background-color: var(--colorGray1);
  color: var(--colorGray4);
  border-color: var(--colorGray1);
  cursor: default;
}

.quantityButton:hover:enabled {
  color: black;
}

.quantityButton:focus {
  outline: 0 none;
}

.stackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  padding: 0;
  color: var(--colorTextDefault);
  background-color: var(--colorGray1);
  border: none;
  border-radius: 3px;
  transition: color 200ms ease, border-color 200ms ease;
  cursor: pointer;
}
