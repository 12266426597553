.formContainer {
  padding: 1.5rem;
  background: white;
}

.field {
  margin-bottom: 1.5rem !important;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.heading {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin: 0;
  margin-bottom: 1.5rem;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.alert {
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
}

.alert svg {
  margin-right: 0.75rem;
}

.alertTitle {
  composes: ui12SemiBold from 'styles/typeography.css';
}

.closeIcon {
  cursor: pointer;
  color: var(--colorGray7);
}
