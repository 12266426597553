.sellerHeader {
  padding-top: 2rem;
}

.sellerLogo {
  display: none;
}

.sellerLogo > img {
  width: 100%;
  border-radius: 0.25rem;
  max-width: 8rem;
}

.sellerDetails,
.sellerDetails.isCompact,
.sellerDetails.isMissingLogo {
  grid-column: 1 / -1;
}

@media (--viewportTabletMin) {
  .sellerDetails {
    grid-column: 2 / -1;
  }

  .sellerLogo {
    display: flex;
    justify-content: flex-end;
  }
}

.logoNameLocationContainer {
  display: flex;
  margin-bottom: 1rem;
}

.sellerLogoCompact {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.sellerDisplayName {
  composes: ui20SemiBold from 'styles/typeography.css';
  font-size: 2rem;
  line-height: 2.25rem;
  margin-bottom: 0.25rem;
}

.sellerMeta {
  display: flex;
  align-items: center;
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray8);
  margin-bottom: 0.125rem;
}

.sellerMetaIcon {
  color: var(--colorGray6);
  margin-right: 0.5rem;
}

.sellerDescription {
  max-width: 40rem;
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray11);
  margin-bottom: 0;
}

.sellerBrandValuesList {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.sellerBrandValuesItem {
  composes: ui14Medium from 'styles/typeography.css';
  padding: 0.5rem 1.5rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--colorTan2);
  border-radius: 0.25rem;
}
