.container {
  max-width: 640px;
  margin-left: 0;
  margin-right: 0;
  padding: 16px 24px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.heading {
  composes: ui16SemiBold from '../../../../../styles/typeography.css';
  margin: 0;
  margin-bottom: 0.5rem;
  color: var(--colorBlack2);
}

.description {
  composes: ui14Regular from '../../../../../styles/typeography.css';
  margin: 0;
  margin-bottom: 16px !important;
  color: var(--colorGray8);
}

.button {
  height: auto !important;
}

@media (--viewportPhoneMax) {
  .button {
    width: 100%;
  }
}

.terms {
  composes: ui11Regular from '../../../../../styles/typeography.css';
  color: var(--colorGray8);
  margin-top: 8px;
}
