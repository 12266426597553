.container {
  width: 100%;
}

.alertContainer {
  margin: 16px 0;
  padding: 12px 16px;
}
.alertTitle {
  composes: ui12SemiBold from 'styles/typeography.css';
  color: var(--colorBlack);
  margin-bottom: 0 !important;
}
.alertContent {
  color: var(--colorGray8);
  margin-top: 0;
  margin-bottom: 0 !important;
}

.link {
  color: var(--colorGray8);
  text-decoration: none;
}

@media (--viewportTabletMax) {
  .alertContainer {
    margin: 12px 0;
  }
}
