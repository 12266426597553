.alert {
  --alertBlueColor: var(--colorGray8);
  --alertBlueBackgroundColor: var(--colorBlue1);
  --alertBlueIconColor: var(--colorBlue8);
  --alertBlueBarColor: var(--colorBlue8);

  --alertRedColor: var(--colorGray8);
  --alertRedBackgroundColor: var(--colorRed1);
  --alertRedIconColor: var(--colorRed8);
  --alertRedBarColor: var(--colorRed8);

  --alertGreenColor: var(--colorGray8);
  --alertGreenBackgroundColor: var(--colorGreen1);
  --alertGreenIconColor: var(--colorGreen8);
  --alertGreenBarColor: var(--colorGreen8);

  --alertYellowColor: var(--colorGray8);
  --alertYellowBackgroundColor: var(--colorYellow1);
  --alertYellowIconColor: var(--colorYellow8);
  --alertYellowBarColor: var(--colorYellow8);

  --alertPurpleColor: var(--colorGray8);
  --alertPurpleBackgroundColor: var(--colorPurple1);
  --alertPurpleIconColor: var(--colorPurple8);
  --alertPurpleBarColor: var(--colorPurple8);

  --alertGrayColor: var(--colorGray8);
  --alertGrayBackgroundColor: var(--colorGray1);
  --alertGrayIconColor: var(--colorGray8);
  --alertGrayBarColor: var(--colorGray8);

  --alertTanColor: var(--colorGray8); /* Note this is deliberately gray8 */
  --alertTanBackgroundColor: var(--colorTan2);
  --alertTanIconColor: var(--colorTan8);
  --alertTanBarColor: var(--colorTan8);

  --alertHintColor: var(--colorBlack2);
  --alertHintBackgroundColor: var(--colorGreen1);
  --alertHintIconColor: var(--colorGreen8);
  --alertHintBarColor: var(--colorGreen8);
}

.default,
.info,
.yellow,
.red,
.green {
  composes: ui12Regular from '../../styles/typeography.css';
}

.alertTitleBlue,
.alertTitleYellow,
.alertTitleGreen,
.alertTitleRed,
.alertTitleSuccess,
.alertTitleHeaderInfo {
  composes: ui12SemiBold from '../../styles/typeography.css';
}

.alert {
  margin: 16px auto auto auto;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  mix-blend-mode: multiply;
}

.alertInner {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.alertIcon {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 1rem;
}

.alertRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.condensed .alertRight {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media (--viewportPhoneMax) {
  .condensed .alertRight {
    flex-direction: column;
    align-items: flex-start;
  }
}

.alertTitle {
  color: var(--colorBlack2);
}

.condensed .alertTitle {
  margin-right: 12px;
  white-space: nowrap;
}

.alertText {
  text-align: left;
  width: inherit;
}

.alertText h4,
.alertText p {
  margin-bottom: 0.5rem;
}

.alertText small {
  display: block;
  line-height: inherit;
  margin-bottom: 0.5rem;
}

.alert.blue {
  color: var(--alertBlueColor);
  background-color: var(--alertBlueBackgroundColor);
}

.alert.blue .alertIcon {
  color: var(--alertBlueIconColor);
}

.alert.red {
  color: var(--alertRedColor);
  background-color: var(--alertRedBackgroundColor);
}

.alert.red .alertIcon {
  color: var(--alertRedIconColor);
}

.alert.green {
  color: var(--alertGreenColor);
  background-color: var(--alertGreenBackgroundColor);
}

.alert.green .alertIcon {
  color: var(--alertGreenIconColor);
}

.alert.yellow {
  color: var(--alertYellowColor);
  background-color: var(--alertYellowBackgroundColor);
}

.alert.yellow .alertIcon {
  color: var(--alertYellowIconColor);
}

.alert.purple {
  color: var(--alertPurpleColor);
  background-color: var(--alertPurpleBackgroundColor);
}

.alert.purple .alertIcon {
  color: var(--alertPurpleIconColor);
}

.alert.gray {
  color: var(--alertGrayColor);
  background-color: var(--alertGrayBackgroundColor);
}

.alert.gray .alertIcon {
  color: var(--alertGrayIconColor);
}

.alert.tan {
  color: var(--alertTanColor);
  background-color: var(--alertTanBackgroundColor);
}

.alert.tan .alertIcon {
  color: var(--alertTanIconColor);
}

.alert.hint {
  color: var(--alertHintColor);
  background-color: var(--alertHintBackgroundColor);
}

.alert.hint .alertIcon {
  color: var(--alertHintIconColor);
}

.alert.hasBar.blue {
  border-left: 6px solid var(--alertBlueBarColor);
}

.alert.hasBar.red {
  border-left: 6px solid var(--alertRedBarColor);
}

.alert.hasBar.green {
  border-left: 6px solid var(--alertGreenBarColor);
}

.alert.hasBar.yellow {
  border-left: 6px solid var(--alertYellowBarColor);
}

.alert.hasBar.purple {
  border-left: 6px solid var(--alertPurpleBarColor);
}

.alert.hasBar.gray {
  border-left: 6px solid var(--alertGrayBarColor);
}

.alert.hasBar.tan {
  border-left: 6px solid var(--alertTanBarColor);
}

.alert.hasBar.hint {
  border-left: 6px solid var(--alertHintBarColor);
}

.hint {
  composes: ui14Regular from '../../styles/typeography.css';
}

.alertTitleHint {
  composes: ui14SemiBold from '../../styles/typeography.css';
}

.dismiss {
  cursor: pointer;
}
