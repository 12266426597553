.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorTan1);
  width: 100%;
}

.bannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  max-width: 1184px;
  width: 100%;
}

.orderDetailsContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.textContent {
  composes: ui12Regular from 'styles/typeography.css';
}

.orderNumber {
  composes: ui12SemiBold from 'styles/typeography.css';
  margin-bottom: 0;
}

.subtext {
  composes: ui12Regular from 'styles/typeography.css';
  margin-bottom: 0;
}

.rightContent {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.shoppingCart {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.grayIcon {
  color: var(--colorGray8);
}

.orderLineItemsCount {
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorGray8);
  margin-bottom: 0;
}

@media (--viewportPhoneMin) {
  .rightContent {
    gap: 8px;
  }
}

@media (--viewportPhoneMax) {
  .bannerContainer {
    padding: 16px 24px;
  }
}
