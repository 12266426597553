.section {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: var(--colorTan2);
}

.messaging {
  display: flex;
  align-items: center;
}

.title {
  composes: brand28 from 'styles/typeography.css';
  margin-bottom: 1rem;
  color: var(--colorGray11);
}

.paragraph {
  margin-bottom: 2rem;
  composes: ui16Medium from 'styles/typeography.css';
  color: var(--colorGray8);
}

@media (--viewportTabletMin) {
  .container {
    display: flex;
  }

  .title {
    /* composes appears to not work as expected inside media queries */
    /* composes: brand40 from '../../../../../styles/typeography.css'; */
    /* Type styles copied verbatim from above reference */
    font-family: var(--fontFamilySerif);
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.015em;
  }
}
