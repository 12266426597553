.container {
}

.title {
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorGray7);
  margin-bottom: 8px;
}

.badgeButtonContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 8px;
}

.badgeButton {
  display: inline;
  white-space: nowrap;
  overflow: inherit;

  border-radius: 4px;
  mix-blend-mode: multiply;
  background: var(--colorTan2);
}

.productCount {
  border-radius: 10px;
  margin-left: 8px;
  background-color: var(--colorTan4) !important;
  composes: ui11SemiBold from 'styles/typeography.css';
  color: var(--colorTan12);
}
