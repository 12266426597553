.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1rem;
  background-color: var(--colorTan2);
}

@media (--viewportPhoneMax) {
  .container div:first-child {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--colorGray3);
  }

  .container a {
    width: 100%;
  }
}

@media (--viewportTabletMin) {
  .container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .container div:first-child {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid var(--colorGray3);
  }
}

/* Using compose to conditionally apply phone (narrow) styles does seem to work, so jsut duplicate here */
.hasNarrowLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.hasNarrowLayout > div:first-child {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--colorGray3);

  padding-right: 0;
  margin-right: 0;
  border-right: 0 none;
}

.hasNarrowLayout a {
  width: 100%;
}

/* Content and Typography */

.heading {
  composes: ui16SemiBold from '../../../../../styles/typeography.css';
  margin: 0;
  margin-bottom: 0.5rem;
}

.description {
  composes: ui14Regular from '../../../../../styles/typeography.css';
  margin: 0;
  margin-bottom: 1.5rem;
  color: var(--colorGray8);
}

.repSignupButton {
  mix-blend-mode: multiply;
}
