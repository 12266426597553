.container {
  width: auto;
  max-width: 640px;
  margin-left: 0;
  padding: 12px 16px;
}

.title {
  composes: ui16SemiBold from '../../../../../styles/typeography.css';
  color: black;
  margin-bottom: 8px;
}

.subtitle {
  composes: ui14Regular from '../../../../../styles/typeography.css';
  color: var(--colorGray8);
  margin-bottom: 24px !important;
}
