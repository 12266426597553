.container {
  margin: 0 !important;
  padding: 1rem !important;
}

.title {
  composes: ui12SemiBold from '../../../../../styles/typeography.css';
  color: black;
  margin: 0;
  margin-left: -4px;
}

.subtitle {
  composes: ui12Regular from '../../../../../styles/typeography.css';
  color: var(--colorGray8);
  margin-left: -4px;
  margin-bottom: 0 !important;
}
